class Sofatutor.Classes.ProfilesIndexPage
  defaults:
    selectors:
      toggleEditModeButton:   '.js-profiles-toggle-edit-mode-button'
      profileCard:            '.js-profile-card'
      pageContainer:          '.profiles-content'
      newProfileCard:         '.profiles-card--new-profile'
      pageHeadline :          '.js-profiles-content-headline'

  constructor: ->
    @$toggleEditModeButton  = $(@defaults.selectors.toggleEditModeButton)
    @$profileCards          = $(@defaults.selectors.profileCard)
    @$pageContainer         = $(@defaults.selectors.pageContainer)
    @$newProfileCard        = $(@defaults.selectors.newProfileCard)
    @$pageHeadline          = $(@defaults.selectors.pageHeadline)
    @editModeActive         = false

    @attachEventListeners()

  attachEventListeners: ->
    @$profileCards.on('click', @handleCardClick)
    @$toggleEditModeButton.on('click', @toggleEditMode)

  handleCardClick: (event) =>
    $currentCard = $(event.currentTarget)

    if $currentCard.is('.is-editable')
      Turbolinks.visit(@editProfilePath($currentCard.data('profile-id')), { action: 'advance' })
    else
      amplitudeEventProperties = $currentCard.data('event-properties')
      Sofatutor.tracking.amplitude.track('profile_selected', amplitudeEventProperties)
      Sofatutor.tracking.googleAnalytics4.trackEvent('profile_selected')
      Sofatutor.profile.set(
        $currentCard.data('profile-id'),
        $currentCard.data('profile-type'),
        $currentCard.data('redirect-path')
      )

  updateElementsText: () =>
    defaultHeadlineText = @$pageHeadline.data('text-default')
    editModeHeadlineText = @$pageHeadline.data('text-edit-mode')
    defaultButtonText = @$toggleEditModeButton.data('text-default')
    editModeButtonText = @$toggleEditModeButton.data('text-edit-mode')

    if @editModeActive
      @$pageHeadline.text(editModeHeadlineText)
      @$toggleEditModeButton.text(editModeButtonText)
    else
      @$pageHeadline.text(defaultHeadlineText)
      @$toggleEditModeButton.text(defaultButtonText)

  toggleEditMode: (event) =>
    unless @editModeActive
      Sofatutor.tracking.amplitude.track('edit_create_view_activated')

    @editModeActive = !@editModeActive

    if $(event.currentTarget).is('.profiles-actions__promoting-button')
      $(event.currentTarget).toggleClass(['animate__animated', 'animate__pulse', 'animate__slow', 'animate__delay-2s'])
    else if $(event.currentTarget).is('.profiles-actions__promoting-class-update')
      $(event.currentTarget).toggleClass(['white', 'purple'])
    else
      $(event.currentTarget).toggleClass(['white', 'green'])

    @$newProfileCard.toggleClass('is-hidden')
    @updateElementsText()

    @$profileCards.each( ->
      if $(this).is('[data-profile-type="student"]')
        $(this).toggleClass('is-editable')
      else if $(this).is('[data-profile-type="parent"]')
        $(this).toggleClass('is-disabled')
    )

  editProfilePath: (profileId) ->
    @$pageContainer.data('edit-page-path').replace('id', profileId)

$ ->
  preventCachedExecution ->
    return unless $('.profiles-content.page-content').length
    new Sofatutor.Classes.ProfilesIndexPage
