class Sofatutor.Classes.EditProfileForm
  defaults:
    selectors:
      form            : '.js-profiles-edit-form'
      formInput       : '.js-profiles-edit-form-input'
      avatar          : '.profiles-avatars-selection__avatar-image'
      bigAvatarBubble : '.profiles-edit-form__avatar > img'

  constructor: ->
    @$form            = $(@defaults.selectors.form)
    @$formInput       = $(@defaults.selectors.formInput, @$form)
    @$avatar          = $(@defaults.selectors.avatar)
    @$bigAvatarBubble = $(@defaults.selectors.bigAvatarBubble)
    @$avatarInput      = $("[name='profile[avatar_file_name]']", @$form)

    @attachEventListeners()

  attachEventListeners: ->
    @$form
      .on('ajax:success', @handleSubmitSuccess)
      .on('ajax:error', @handleSubmitError)
    @$formInput.on('change', @handleInputChange)
    @$avatar.on('click', @handleAvatarSelection)

  handleSubmitSuccess: =>
    eventProperties = { avatar: @$avatarInput.val(), profile_id: Sofatutor.profile.currentProfileId(), profile_level: Sofatutor.user.level, profile_type: Sofatutor.profile.currentProfileType() }
    Sofatutor.tracking.amplitude.track('profile_pic_selected', eventProperties)
    Turbolinks.visit(@$form.data('redirect-path'), { action: 'replace' })

  handleSubmitError: (_event, xhr) =>
    for errorName, errorMessage of xhr.responseJSON
      $input = $("[name='profile[#{errorName}]']", @$form)

      $input.addClass('error')
      $input.after("<span class='form-error-text text-xsmall'>#{errorMessage}</span>")

  handleInputChange: (event) =>
    @$formInput.removeClass('error')
    @$formInput.next('.form-error-text').remove()

  handleAvatarSelection: (event) =>
    $avatarElem = $(event.currentTarget)

    @$avatar.removeClass('is-selected')
    $avatarElem.addClass('is-selected')

    @$avatarInput.val($avatarElem.data('id'))
    @$bigAvatarBubble.attr('src', $avatarElem.attr('src'))

$ ->
  return unless $('.js-profiles-edit-form')[0]
  new Sofatutor.Classes.EditProfileForm
